    .about {
        padding: 25px 20px;
        background: #05203A;
    }  

    .about__container {
        display: flex;
        flex-flow: column;
        align-items: center;
        margin: 20px 0;
    }

    .about__container > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    p {
        font-family: 'Montserrat', sans-serif;
        color: #fff;
        font-size: 20px;
        margin-top: 10px;
    }

    .about__container > img {
        max-width: 100%;
        height: auto;
    }

    .about__image {
        max-width: 100%;
        height: auto;
        place-self: center;
    }

    .about__text{
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;
        width: 80%;
        margin: 0 auto;
        color: #fff;
    }

    .about__detail{
        font-family: 'Montserrat', sans-serif;
        margin: 7px 0px;
        text-align: left;
        color: rgba(190, 151, 120, 1);
        line-height: 1.3;
    }

    .about__detail2{
        font-family: 'Montserrat', sans-serif;
        margin: 7px 0px;
        align-self: baseline;
        text-align: left;
        color: rgba(190, 151, 120, 1);
        line-height: 1.3;
    }

    .about__header{
        font-family:'Trajan Pro Regular';
        font-size: 35px;
        margin: 30px 0;
        color: rgba(190, 151, 120, 1);
        border-bottom: 1px solid rgba(190, 151, 120, 1);
    }

    .player-wrapper {
        position: relative;
        padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
    }

    .about__video {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 25px;
        color: #fff;
        margin: 20px 0;
    }

    /* .about__sertifikat{
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        padding-bottom: 10px;
        margin-bottom: 20px;
        font-size: 30px;
        border-bottom: 1px solid rgba(190, 151, 120, 1);
    } */

    .about__list{
        text-align: left;
        margin-left: 20px;
        color: rgba(190, 151, 120, 1);
    }

    .list__highlight{
        font-family: 'Montserrat', sans-serif;
        font-style: italic;
        font-weight: bold;
    }

    .about__video > .about__detail {
        margin: 30px 0px;
    }

    li{
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        margin: 10px 0;
        line-height: 1.3;
    }
      
    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }

    @media screen and (max-width: 960px) {
        .about__video {        
            grid-template-columns: none;
            grid-template-rows: 1fr 1fr;
            row-gap: 25px;
        }

        .about__text{
            text-align: left;
        }
    
        .about__detail > p{
            font-size: 16px;
            margin-left: 5px;
            display: inline-block;
        }

        #detail__more1, #detail__more2{
            display: none;
        }
    }