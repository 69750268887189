.footer{
    padding: 25px 0px;
    background: black;
}

.company__address{
    text-align: center;
    padding: 20px;
    color: #fff;
    border-top: 1px solid white;
}

.company__address > b {
    color: #fff;
}

.company__address > div {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    margin: 5px 0;
}

.company__address > .address__detail{
    font-weight: 400;
}

.sosmed__icon{
    text-align: center;
    padding: 20px;
    border-top: 1px solid white;
}

.sosmed__icon > p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.sosmed__wrapper {
    position: relative;
    margin: 30px 0 45px;
  }
  
  .sosmed__images{
    display: inline-block;
    place-items: center;
  }

  .sosmed__image{
      width: 40px;
      height: 40px;
      margin: 0px 20px;
  }

  @media screen and (max-width: 960px) {
    .company__address{
        font-size: 14px;
    }

    .address__header{
        font-size: 16px;
    }
 
  }