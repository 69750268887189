.cards {
    padding-bottom: 25px;
    background: black;
  }

  .cards__header{
    display: flex;
    margin: 20px auto;
  }
  
  .cards__banner{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 40px -20px;
  }

  .cards__product{
    width: 100%;
    place-self: center;
  }

  .caps__break{
    margin-top: 40px;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    background: #fff;
  }

  #flat{
    padding-bottom: 30px;
  }

  .cards__container > img {
    margin: 30px 0px;
  }

  .slide-container {
    margin-top: 40px;
    padding: 50px;
    background-color: #fff;
  }

  .image-container > img {
    width: 100%;
    height: auto;
  }

  .dot {
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin: 0 5px;
    border: 1px solid rgb(230, 230, 230);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }

  #clear{
    background-color: #fff;
  }
  #black{
    background-color: black;
  }
  #red{
    background-color: red;
  }
  #blue{
    background-color: blue;
  }
  #green{
    background-color: green;
  }
  #yellow{
    background-color: yellow;
  }
  #pink{
    background-color: rgb(255, 0, 170);
  }
  #purple{
    background-color: purple;
  }

  .cards__wrapper {
    position: relative;
    margin: 30px 0 45px;
  }
  
  .cards__items {
    margin-bottom: 24px;
  }
  
  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    /* position: absolute; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__info {
    padding: 20px;
  }
  
  .cards__item__text {
    color: #252e48;
    font-size: 12px;
    line-height: 24px;
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: 426px) {
    .dot {
      height: 20px;
      width: 20px;
      margin: 0 2px;
    }

    .cards__container > img {
      width: 80%;
    }

    .cards__header{
      width: 80%;
    }

    .slide-container {
      padding: 20px;
    }
  }